<template>
    <div v-loading="loading">
		<p class="m-right-title">案例库</p>
		<div class="m-con">
			<div class="hzy-wrap">
				<div class="m-font1">{{currText.title}}（{{params1.page}}/{{zongpage}}）</div>
                <div class="anli_text m-font1" v-html="currText.detail"></div>
            </div>
            <div class="hzy-wrap" v-for="(item,index) in currentData" :key="item.id">
				<p class="">
					<span class="m-title-label" v-if="item.type == 1">【单选题】</span>
					<span class="m-title-label" v-if="item.type == 2">【判断题】</span>
					<span class="m-title-label" v-if="item.type == 3">【多选题】</span>
					{{index+1}}.{{item.title}}
				</p>
				<div class="u-m-t-30 m-content-option" >
					<template v-if="item.type == 1 || item.type == 2">
						<el-radio-group v-model="radioArray[index]">
							<div class="u-m-t-10" v-for="(item2,index2) in item.options" :key="index2">
								<el-radio  :label="item2.key" :key="item2.key"   @change="handleRadioChanges(item.id,index)">
									{{item2.key}} .{{item2.value}}
								</el-radio>
							</div>
						</el-radio-group>
					</template>
					<template v-if="item.type == 3">
						<el-checkbox-group v-model="checkArray[index]" @change="handleChange(item.id,index)">
							<template v-if="item && item.options">
								<div class="u-m-t-10" v-for="(item2,index2) in item.options" :key="index2">
									<el-checkbox  :label="item2.key" >
										{{item2.key}} .{{item2.value}}
									</el-checkbox>
								</div>
							</template>
							
						</el-checkbox-group>
					</template>
					<template v-if="item.type == 4">
						<el-input :autosize="{ minRows: 4, maxRows: 8}" type="textarea" :value="item.myAnswer" @input="inputChange($event,index)"  placeholder="请填写您的答案"></el-input>
					</template>
				</div>
				<div class="m-btn2 u-m-t-30" @click="submit(index,item.type,item)">提交</div>

				<template v-if="answerData[index].your_answer!=''">
					<p class="u-m-t-30">
						<span class="m-font1 m-title-label">【您的答案】</span>
						<span class="m-font1 u-m-l-4" v-for="data in answerData[index].your_answer">
							{{data}}
						</span>
						<!-- <span class="m-font2 u-m-l-4">{{answerData[index].your_answer}}</span> -->
					</p>
					<p class="u-m-t-10">
						<span class="m-font1 m-title-label">【正确答案】</span>
						<!-- <span class="m-font1 u-m-l-4">{{answerData[index].answer}}</span> -->
						<span class="m-font1 u-m-l-4" v-for="data in answerData[index].answer">
							{{data}}
						</span>
					</p>
					<p class="u-m-t-10">
						<span class="m-font1 m-title-label">【答案解析】</span>
					</p>
					<div class="u-m-t-5 u-m-l-10 m-font-html" v-html="answerData[index].analysis"></div>
				</template>
            </div>
			
			<div class="u-flex u-row-between u-m-t-60">
				<div class="u-flex">
					<span class="m-btn" @click="nextCase(0)">上一个案例</span>
					<span class="m-btn u-m-l-16" @click="nextCase(1)">下一个案例</span>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	import {to_link} from "@/router/api"
	import {getCaseQuestions,practiceCase} from "@/api/center.js"
    export default {
        data(){
			return {
				ceshi:'',
				loading:false,
				params1: {
					page: 1,
					page_num: 1,
					course_id:''
				},
				params2: {
					question_id: null,
					answer: {},
				},
				currText:[],
				currentData:[],
				answerData:[],

				radioArray:[],
				checkArray:[],
				zongpage:1,				
			}
		},
		created(){
			this.params1.course_id = this.$route.query.course_id;
			this.getCaseQuestions();					
		},
		methods:{
			inputChange(e,i){
				let item = JSON.parse(JSON.stringify(this.currentData[i]))
				item['myAnswer'] = e
				this.$set(this.currentData,i,item)
			},
			// 单选取值
			handleRadioChanges(id,index) {
				let arr = this.radioArray[index];
				// console.log(id);
				// console.log(arr);
				this.params2.question_id = id
				this.params2.answer = arr;
				// this.practiceCase();
			},
			// 多选取值
			handleChange (id,index) {
				let arr = this.checkArray[index]
				// console.log(arr);
				// console.log(id);
				this.params2.question_id = id
				this.params2.answer = arr;
				// this.practiceCase();
			},
			// 提交
			submit(index,type,item){
				// console.log(index,type)
				if(type == 1 || type == 2){
					// console.log(this.radioArray[index])
					if(this.radioArray[index]==undefined){
						this.$message.error('请选择选项');
						return false;
					}

				}else if(type == 3){
					// console.log(this.checkArray[index]);
					if(this.checkArray[index].length<=0){
						this.$message.error('请选择选项');
						return false;
					}
				}else if(type == 4){
					this.params2.question_id = item.id
					this.params2.answer = item.myAnswer
				}
				this.practiceCase(index);
			},
			// 下一个案例
			nextCase(num){
				console.log(num);
				console.log(this.params1.page);
				console.log(this.zongpage);
				if(num == 0){
					if(this.params1.page == 1){
						this.$message.error('当前是第一个案例');
						return false;
					}else{
						this.params1.page = this.params1.page - 1;
						this.getCaseQuestions()
					}					
				}
				if(num == 1){
					console.log(this.zongpage);
					if(this.params1.page == this.zongpage){
						this.$message.error('当前是最后一个案例');
						return false;
					}else{
						this.params1.page = this.params1.page + 1;
						this.getCaseQuestions()
					}					
				}
			},
			// 获取案例题
			getCaseQuestions(){
				this.loading = true;
				getCaseQuestions({
					...this.params1,
				}).then(res=>{
					console.log(res);					
					if (res.code == 200) {
						this.loading = false;
						this.currText = res.data;
						this.zongpage = res.count;
						let list = res.data.questions || {};
						if (list.length > 0) {
							let checkObj = {}
							this.checkArray = list.forEach((v, i) => {
								if(v.type == 3) {
								  checkObj [i] = []
								}
							});
							this.checkArray = checkObj
							list.forEach(item=>{
								item['myAnswer'] = ''
							})
							this.currentData = list
							for (let index = 0; index < list.length; index++) {
								this.answerData[index]={
									your_answer:"",
									answer:"",
									analysis:"",
								};
							}
							
						}
					}
				})
			},
			// 获取答案
			practiceCase(index){
				this.loading = true;
				practiceCase({
					...this.params2
				}).then(res=>{
					// console.log(res);
					if(res.code == 200){
						this.answerData[index] = res.data;
						this.$set(this.answerData,index,this.answerData[index]);
						// console.log(this.answerData)
						this.loading = false;
					}else{
						this.loading = false
						this.$message.error(res.message)
					}
					
				})
			}
			
		}
        
    }
</script>

<style lang="scss" scoped>
    .m-font1{
		font-size: 14px;
		color: #474747;
		line-height: 25px;
	}
	.m-font-html{
		font-size: 14px;
		color: #474747;
		line-height: 20px;
	}
	.m-title-label{
		color: red; 
	}
	.m-right-title{
		display: flex;
		height: 64px;
		line-height: 20px;
		padding-top: 32px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #000000;
		padding-left: 38px;
		border-bottom: 1px solid #EEEEEE;
		box-sizing: border-box;
	}
	
    .hzy-wrap{padding-bottom: 30px;border-bottom: 1px solid #e3e3e3;margin-bottom: 30px;
		p{
			font-size: 16px;
			font-family: '宋体';
		}
	}
	.anli_text{
		margin-top: 20px;
		p{
			margin-top: 20px;
		}
	}
	.m-con{
		margin: 30px 54px 70px 54px;
        .u-row-between{
            justify-content: flex-end;
        }
		.m-btn{
			width: 110px;
			height: 33px;
			background: #FFFFFF;
			border: 1px solid #DCDCDC;
			text-align: center;
			line-height: 33px;
			font-size: 14px;
			color: #4D4D4D;
			cursor: pointer;
            &:hover{
                color: #fff;
                background-color: #F8A310;
                transition: all .2s;
            }
		}		
		
	}
	.m-btn2{
		width: 87px;
		height: 33px;
		background: #E2E2E2;
		border: 1px solid #DCDCDC;
		text-align: center;
		line-height: 33px;
		font-size: 14px;
		color: #4D4D4D;
		cursor: pointer;
	}


</style>
<style lang="scss">
	.m-con {
		.el-radio__input.is-checked .el-radio__inner {
			border-color: #F8A310;
			background: #F8A310;
		}

		.el-radio__label{
			font-size: 16px;
			font-family: '宋体';
		}
		.el-radio__input.is-checked+.el-radio__label {
			color: #F8A310;
		}

		.el-checkbox__input.is-checked+.el-checkbox__label {
			color: #F8A310;
		}

		.el-checkbox__input.is-checked .el-checkbox__inner,
		.el-checkbox__input.is-indeterminate .el-checkbox__inner {
			background-color: #F8A310;
			border-color: #F8A310;
		}
		
	}
	.m-con .el-scrollbar__wrap{
		overflow-x: hidden;
	}
	.m-content-option{
		.el-radio{
			white-space:pre-wrap;
		}
		.el-checkbox {
			white-space:pre-wrap;
		}
	}
</style>